<template>
    <div class="wraper" v-if="detail">
        <Header :isShow="isShow" />
        <!-- <div class="banner">
            <img :src="imgUrl + banner" alt="" />
        </div> -->
        <div class="content max_big_box" ref="header" >
             <div class="title max_small_box">
                <p >{{detail.title}}</p>
            </div>
            <p class="time">发布时间：{{detail.releasetime.split(' ')[0]}}</p>
            <div class="line"></div>
            <div class="detail max_small_box"  v-html="detail.detail"></div>
            <a :href="imgUrl + file" target="_blank" class="file" v-if="file!=''">{{detail.title}}</a>
        </div>
    </div>
</template>

<script>
import { onUnmounted, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { get, post } from '../../utils/request';
import * as api from '../../utils/api';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FloatingFrame from '../../components/floatingFrame/FloatingFrame';

//页面滚动处理函数
const ScrollEffect = () => {

    const isShow = ref()

    const handleScroll = (height) => {
        
        if(document.documentElement.scrollTop >= height){
            isShow.value = 1
        }else{
            isShow.value = 2
        }
    }

    return { handleScroll, isShow }
}

//详情处理模块
const detailEffect = () => {

    //轮播图
    const banner = ref()

    //详情
    const detail = ref()

    //附件
    const file = ref()

     //获取详情
    const getDetail = async (id) => {
        let postData = { id }
        try{
            const result = await post('/news/get_news_detail', postData)
            // console.log('新闻详情', result)
            if(result.code == 1){
                // result.data.detail = result.data.detail.replace(/<a[^>]*>/gi, function (match, capture) {
                //     return match.replace(/href="http:/ig,'href="'+api.imgUrl+'') // 替换src
                // })

                if(result.data.file=='' || result.data.file==null){
                    file.value = ''
                }else{
                    file.value = result.data.file
                }
                detail.value = result.data
            }else{
                // console.log('请求失败')
            }
        }catch(e) {
        
        }
    } 

    //获取轮播图
    const getBanner = async () => {
        let postData = {
            page_id: '/news'
        }
        await post('/carousel/get_carousel', postData).then((res) => {
            // console.log('11轮播图', res)
            if(res.code == 1) {
                banner.value = res.data.images
            }
        })
    }

    return { detail, getDetail, getBanner, banner, file }
}

export default {
    name: 'NewsDetail',
    components: { Header, Footer, FloatingFrame },
    setup() {
        const route = useRoute()
        //页面滚动处理函数
        const { 
            handleScroll, 
            isShow 
        } = ScrollEffect()

        //详情处理模块
        const { detail, getDetail, getBanner, banner, file } = detailEffect()

        //获取滑块到顶部的距离
        const header = ref(null)

        getBanner()

        onMounted(() => {
            let idjson = route.params.id.split('&')
            if(idjson.length > 1){
                getDetail(idjson[0])
            }else{
                getDetail(route.params.id)  //获取详情
            }
            document.documentElement.scrollTop = document.body.scrollTop =0;
            window.addEventListener('scroll', function () {
                if(header.value){
                    handleScroll(header.value.offsetTop);
                }
            })
        });

        onUnmounted(() => {
            document.querySelector('body').setAttribute('style', 'background-color:#fff')
        })

        const { imgUrl }  = api

        return { imgUrl, isShow, header, detail, banner, file }
    }
}
</script>

<style lang="scss" scoped>
@import '../../style/viriables.scss';
/* 轮播图 */
.banner{
    margin-top: 114px;
    @media screen and (max-width: 1200px){
        margin-top: 92px;
    }
    img{
        width: 100%;
        display: block;
    }
}
.content{
    margin-top: 50px;
    @media screen and (max-width: 900px){
        margin-top: 30px;
    }
    .title{
        text-align: center;
        @media screen and (max-width: 1200px){
            padding: 0 30px;
        }
        p{
            color: rgba(16, 16, 16, 100);
            font-size: 26px;
            font-weight: 600;
            line-height: 60px;
            @media screen and (max-width: 900px){
                font-size: .18rem;
                line-height: 22px;
            }
        }
    }
    .time{
        color: $all-desc-color;
        font-size: 14px;
        margin-top: 30px;
        text-align: center;
        @media screen and (max-width: 900px){
            font-size: .14rem;
        }
    }
    /* .line{
        width: 100%;
        height: 2px;
        background: rgba(187, 187, 187, 100);
        margin-top: 30px;
    } */
    .detail{
        margin-top: 30px;
        width: 100%;
        div{
            width: 100%;
            img{
                width: 100%;
            }
        }
        img{
            width: 100%;
        }
    }
    img{
        width: 100%;
    }
    .file{
        width: 100%;
        text-align: center;
        display: block;
    }
}

</style>